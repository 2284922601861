import $ from 'jquery';

export default class Episode {
  constructor(context) {
    this.context = context;
  }

  handleClick() {
    $(this.context).on('click', '.lead', (e) => {
      e.preventDefault();

      let $context = $(this.context),
          activeClass = 'active';

      if ($context.hasClass(activeClass)) {
        $context.removeClass(activeClass);
      } else {
        $context.siblings().removeClass(activeClass);
        $context.addClass(activeClass);
      }
    });
  }

  init() {
    this.handleClick();
  }
}