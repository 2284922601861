import $ from 'jquery';
import config from 'config';
import imagesLoaded from 'imagesloaded';
import Hammer from 'hammerjs';
require('waypoints/lib/noframework.waypoints.js');

export default class Article {
  constructor(context) {
    this.context = context;
  }

  initLoadedClass() {
    setTimeout(() => {
      $(config.historySelector).addClass('loaded');
    }, 3000);
  }

  handleHammer() {
    var hammertime = new Hammer(this.context, {
      cssProps: {
        userSelect: true
      }
    });

    hammertime.on('tap', () => {
      $(config.historySelector).removeClass('loaded');
      this.initLoadedClass();
    });
  }

  handleWaypoints() {
    let stuckClass = 'stuck';

    imagesLoaded($(config.historySelector), () => {
      $(this.context).find('.sticky').each((index, element) => {
        let waypoint = new Waypoint({
          element: element,
          offset: '135px',
          handler: (direction) => {
            if (direction == 'down') {
              $('.' + stuckClass).removeClass(stuckClass);
              $(element).addClass(stuckClass);
            } else {
              let index = $(this.context).find('.sticky').index($(element));
              if (index) {
                $(this.context).find('.sticky:eq(' + (index - 1) + ')').addClass(stuckClass);
              }
              $(element).removeClass(stuckClass);
            }
          }
        });
      });
    });
  }

  init() {
    this.initLoadedClass();
    this.handleHammer();
    this.handleWaypoints();
  }
}