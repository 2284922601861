import $ from 'jquery';
import PubSub from 'pubsub-js';
global.jQuery = $;
require('jquery-placeholder');

export default class Form {
  constructor(context) {
    this.context = context;
  }

  handleSubmit() {
    $(this.context).on('submit', (e) => {
      e.preventDefault();

      const $context = $(this.context);

      console.log(this.context, $context);

      $.ajax({
        url: $context.attr('action'),
        type: $context.attr('method'),
        data: $context.serialize(),
        success: (data) => {
          this.handleData(data);
        }
     });
    });
  }

  handleData(data) {
    let $returned = $(data).find('.' + $(this.context).attr('class')),
        returnedHtml = $returned.html();

    $(this.context).html(returnedHtml);
    this.placeholderFallback();

    if ($(this.context).find('.error').length == 0) {
      setTimeout(() => {
        PubSub.publish('/form/submitted');
      }, 1000);
    }
  }

  placeholderFallback() {
    if ($(this.context).parents('.modal-item').length == 0) {
      $(this.context).find('input, textarea').placeholder();
    }
  }

  init() {
    this.handleSubmit();
    this.placeholderFallback();
  }
}