import $ from 'jquery';
import config from 'config';
import Cookies from 'cookies-js';
import initBehaviors from 'modules/behaviors';
import PubSub from 'pubsub-js';

export default class History {
  constructor(context) {
    this.context = context;
    this.ajaxingClass = this.ajaxingClass;
  }

  handleClick() {
    if (window.history && history.pushState) {
      $('body').on('click', 'a.history', (e) => {
        e.preventDefault();
        PubSub.publish('/history/started');
        this.$link = $(e.currentTarget);
        this.getContent(this.$link.attr('href'), true);
      });
    }
  }

  handlePopstate() {
    window.addEventListener('popstate', () => {
      this.getContent(location.pathname, false);
    });
  }

  getContent(url, doHistoryPush) {
    $(config.historySelector).addClass(this.ajaxingClass);

    this.historyClass = this.$link.attr('data-history-class');

    if (this.historyClass) {
      $(config.historySelector).addClass(this.historyClass);
    }

    this.delayTime = this.$link.attr('data-history-delay-time');

    if (this.delayTime) {
      this.setStartTime();
    }

    $.ajax({url: url})
      .done((data) => {
        if (this.delayTime) {
          let readyTime = new Date,
              timeDifference = readyTime - this.startTime;

          if (timeDifference > this.delayTime) {
            this.performUpdate(url, data, doHistoryPush);
          } else {
            setTimeout(() => {
              this.performUpdate(url, data, doHistoryPush);
            }, this.delayTime - timeDifference);
          }
        } else {
          this.performUpdate(url, data, doHistoryPush);
        }
      })
      .error(() => {
        $(config.historySelector).removeClass(this.ajaxingClass);
      });
  }

  setStartTime() {
    this.startTime = new Date;
  }

  performUpdate(url, data, doHistoryPush) {
    let title = $(data).filter('title').text();

    this.updatePage(title, $(data).filter(config.historySelector));

    if (doHistoryPush) {
      this.pushHistory(title, url);
    }

    $(config.historySelector).removeClass(this.ajaxingClass);

    if (this.historyClass) {
      $(config.historySelector).removeClass(this.historyClass);
    }

    PubSub.publish('/history/updated');
  }

  updatePage(title, $content) {
    if (title) {
      this.updateTitle(title);
    }

    $content.find('[data-cookie-hide]').each((index, element) => {
      let name = $(element).attr('data-cookie-hide'),
          value = Cookies.get(name);

      $content.find('[data-cookie-hide="' + name + '"][data-cookie-hide-value="' + value + '"]').remove();
    });

    if ($content) {
      this.updateContent($content);
    }
  }

  updateTitle(title) {
    $('title').text(title);
  }

  updateContent(content) {
    $(config.historySelector).replaceWith(content);
    $('body').scrollTop(0);
    $('.parallax-mirror').remove();
    initBehaviors($(content));
  }

  pushHistory(title, url) {
    history.pushState({}, title, url);
  }

  init() {
    this.handleClick();
    this.handlePopstate();
  }
}