import $ from 'jquery';
import PubSub from 'pubsub-js';

export default class ModalAutoOpen {
  constructor(context) {
    this.context = context;
  }

  handleAutoOpen() {
    PubSub.publish('/modal/autoOpen', {
      $target: $(this.context)
    });
  }

  init() {
    this.handleAutoOpen();
  }
}