import $ from 'jquery';
import config from 'config';
import Hammer from 'hammerjs';
import PubSub from 'pubsub-js';
require('slick-carousel');

export default class Gallery {
  constructor(context) {
    this.context = context;
  }

  initGallery() {
    this.slick = $(this.context).find('.gallery-items').slick({
      accessibility: false,
      arrows: false,
      centerMode: true,
      centerPadding: '0',
      ease: 'cubic-bezier(.62, .28, .23, .99)',
      infinite: false,
      speed: 300
    });
  }

  initTotalSlides() {
    this.totalSlides = this.slick.find('.slick-slide').length;
  }

  initMouseMoveTimeout() {
    this.timeout = 0;
  }

  handleChange() {
    this.slick.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      this.updateClasses(nextSlide);
      this.updateIndicator(nextSlide);
      this.updatePervasive(nextSlide);
      this.updateDropShadows(nextSlide);
      this.updateDescriptionPosition(nextSlide);
      this.updateSlideIndicatorColor(nextSlide);
    });
  }

  handleClicks() {
    $(this.context).on('click', '.previous', (e) => {
      e.preventDefault();
      $(this.slick).slick('slickPrev');
    });

    $(this.context).on('click', '.next', (e) => {
      e.preventDefault();
      $(this.slick).slick('slickNext');
    });

    $(this.context).on('click', '.thumbnail-item', (e) => {
      e.preventDefault();
      $(this.slick).slick('slickGoTo', $(e.currentTarget).attr('data-position'));
      PubSub.publish('/sidebar/close');
    });
  }

  handleKeypress() {
    $(document).on('keyup.gallery', (e) => {
      if (e.keyCode === 39) {
        $(this.context).find('.next').click();
      }

      if (e.keyCode === 37) {
        $(this.context).find('.previous').click();
      }
    });
  }

  unhandleKeypress() {
    $(document).off('keyup.gallery');
  }

  handleMouseMove() {
    $(document).on('mousemove.gallery', () => {
      this.removeHideElementsClass();
      this.mouseMoveLogic();
    });
  }

  mouseMoveLogic() {
    this.clearMouseMoveTimeout();
    this.timeout = setTimeout(() => {
      this.addHideElementsClass();
    }, 2500);
  }

  clearMouseMoveTimeout() {
    clearTimeout(this.timeout);
  }

  unhandleMouseMove() {
    $(document).off('mousemove.gallery');
  }

  addHideElementsClass() {
    if (!$(this.context).hasClass('last-slide')) {
      $(config.historySelector).addClass('hide-elements');
    }
  }

  removeHideElementsClass() {
    $(config.historySelector).removeClass('hide-elements');
  }

  updateClasses(i) {
    if (i !== 0) {
      $(this.context).addClass('not-first-slide');
    } else {
      $(this.context).removeClass('not-first-slide');
    }

    if (i === (this.totalSlides - 1)) {
      $(config.historySelector).addClass('gallery-finished');
      $(this.context).addClass('last-slide');
      this.removeHideElementsClass();
    } else {
      $(config.historySelector).removeClass('gallery-finished');
      $(this.context).removeClass('last-slide');
      this.mouseMoveLogic();
    }
  }

  updateIndicator(i) {
    $(this.context).find('.slide-indicator .current').text(i);
    $(this.context).find('.slide-indicator .total').text(this.totalSlides - 2);
  }

  updatePervasive(i) {
    let color = $(this.context).find('[data-slick-index="' + i + '"]').children().attr('data-pervasive-color');

    if (color) {
      PubSub.publish('/pervasive/colorReplace', {
        color: color
      });
    } else {
      PubSub.publish('/pervasive/colorReset');
    }
  }

  updateDropShadows(i) {
    let dropShadows = $(this.context).find('[data-slick-index="' + i + '"]').children().attr('data-drop-shadows');

    if (dropShadows) {
      $(config.historySelector).removeClass(config.noShadowClass);
    } else {
      $(config.historySelector).addClass(config.noShadowClass);
    }
  }

  updateDescriptionPosition(i) {
    let descriptionPosition = $(this.context).find('[data-slick-index="' + i + '"]').children().attr('data-description-position');

    if (descriptionPosition !== 'center') {
      $(config.historySelector).removeClass('hide-slide-indicator');
    } else {
      $(config.historySelector).addClass('hide-slide-indicator');
    }
  }

  updateSlideIndicatorColor(i) {
    let slideIndicatorColor = $(this.context).find('[data-slick-index="' + i + '"]').children().attr('data-slide-indicator-color');

    if (slideIndicatorColor) {
      $('.slide-indicator').css('color', slideIndicatorColor);
    } else {
      $('.slide-indicator').removeAttr('style');
    }
  }

  handleHammer() {
    var hammertime = new Hammer(this.context);

    hammertime.on('tap', () => {
      this.removeHideElementsClass();
      this.mouseMoveLogic();
    });
  }

  init() {
    this.initGallery();
    this.initTotalSlides();
    this.initMouseMoveTimeout();
    this.handleChange();
    this.handleClicks();
    this.handleKeypress();
    this.handleMouseMove();
    this.mouseMoveLogic();
    this.handleHammer();
  }

  cleanup() {
    this.clearMouseMoveTimeout();
    this.unhandleKeypress();
    this.unhandleMouseMove();
  }
}