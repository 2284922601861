import $ from 'jquery';
import config from 'config';
import PubSub from 'pubsub-js';

export default class NavMainControls {
  constructor(context) {
    this.context = context;
    this.activeClass = 'active';
    this.latestClass = 'latest';
    this.location = window.location.href;
  }

  saveState() {
    this.location = window.location.href;
  }

  handleClick() {
    $(this.context).on('click', '.nav-main-control', (e) => {
      e.preventDefault();

      let $context = $(e.currentTarget),
          $sibling = $context.siblings('.' + this.activeClass);

      if ($context.parents('.sidebar').length > 0) {
        if ($(config.historySelector).hasClass('sidebar-open')) {
          PubSub.publish('/sidebar/close');
        }
      }

      if ($context.hasClass(this.activeClass)) {
        if ($context.hasClass(this.latestClass)) {
          e.stopPropagation();
        } else {
          if ($sibling.length == 0 && $(config.historySelector).hasClass('home')) {
            this.openItem($context.siblings('.' + this.latestClass));
          }

          this.closeItem($context);
        }
      } else {
        if ($context.hasClass(this.latestClass)) {
          if ($(config.historySelector).hasClass('home')) {
            e.stopPropagation();

            if ($sibling.length > 0) {
              this.closeItem($sibling);
            }
          }
        } else {
          if ($sibling.length > 0) {
            this.closeItem($sibling);
          }

          this.openItem($context);
        }
      }

    });
  }

  closeItem($context) {
    $context.removeClass(this.activeClass);
    $(config.historySelector).removeClass('menu-open');
    $(config.historySelector).removeClass('keep-menu');

    if (!$context.hasClass(this.latestClass)) {
      $($context.attr('href')).removeClass(this.activeClass);
      this.removePervasiveOverride();

      this.resetState();
    }
  }

  openItem($context) {
    $context.addClass(this.activeClass);
    $(config.historySelector).addClass('menu-open');

    if ($context.hasClass('keep-menu')) {
      $(config.historySelector).addClass('keep-menu');
    }

    if (!$context.hasClass(this.latestClass)) {
      $($context.attr('href')).addClass(this.activeClass);

      if (window.history && history.pushState) {
        let href = $context.attr('href'),
            baseUrl = $('[role="banner"] .logo').attr('href'),
            substring = href.substring(1),
            url = baseUrl + '/' + substring + '/';

        history.replaceState({}, null, url);
      }
    }
  }

  removePervasiveOverride() {
    PubSub.publish('/pervasive/removeOverride');
  }

  handleTopics() {
    PubSub.subscribe('/history/started', () => {
      this.resetState();
    });

    PubSub.subscribe('/history/updated', () => {
      this.saveState();
    });
  }

  resetState() {
    if (window.history && history.pushState) {
      history.replaceState({}, null, this.location);
    }
  }

  init() {
    this.saveState();
    this.handleClick();
    this.handleTopics();
  }

  cleanup() {
    PubSub.unsubscribe('/history/started');
    PubSub.unsubscribe('/history/updated');
  }
}