import $ from 'jquery';
import Hammer from 'hammerjs';

export default class SliderSwipe {
  constructor(context) {
    this.context = context;
  }

  handleSwipe() {
    var hammertime = new Hammer(this.context);

    let $next;

    hammertime.on('swiperight', () => {
      $next = $('.slider').find('.item.open').prev();

      if ($next.length == 0) {
        $next = $('.slider').find('.item:last');
      }

      $next.click();
    });

    hammertime.on('swipeleft', () => {
      $next = $('.slider').find('.item.open').next();

      if ($next.length == 0) {
        $next = $('.slider').find('.item:first');
      }

      $next.click();
    });
  }

  init() {
    this.handleSwipe();
  }
}