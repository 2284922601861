import $ from 'jquery';
import PubSub from 'pubsub-js';

export default class SidebarControl {
  constructor(context) {
    this.context = context;
  }

  handleClick() {
    $(this.context).on('click', (e) => {
      e.preventDefault();

      if ($(this.context).hasClass('active')) {
        PubSub.publish('/sidebar/close');
      } else {
        PubSub.publish('/sidebar/open', {
          $context: $(this.context)
        });
      }
    });
  }

  init() {
    this.handleClick();
  }
}