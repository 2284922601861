import $ from 'jquery';
import config from 'config';
import PubSub from 'pubsub-js';

export default class Sidebar {
  constructor(context) {
    this.context = context;
  }

  handleClick() {
    $(this.context).on('click', (e) => {
      if ($(e.target).hasClass('sidebar')) {
        this.closeSidebar();
      }
    });
  }

  handleTopics() {
    PubSub.subscribe('/sidebar/close', () => {
      this.closeSidebar();
    });

    PubSub.subscribe('/sidebar/open', (topic, object) => {
      this.openSidebar(object.$context);
    });
  }

  openSidebar($context) {
    this.handleNavMain();

    let activeClass = 'active',
        $target = $($context.attr('href'));

    $('.sidebar').removeClass(activeClass);
    $('[data-behavior*="sidebarControl"]').removeClass(activeClass);

    $context.addClass(activeClass);
    $target.addClass(activeClass);
    $(this.context).addClass(activeClass);

    $(config.historySelector).addClass('sidebar-open');
  }

  handleNavMain() {
    let $active = $('.sidebar .nav-main .active');

    if ($active.length > 0 && !$active.hasClass('latest')) {
      $active.click();
    }
  }

  closeSidebar() {
    let activeClass = 'active';

    $(this.context).removeClass(activeClass);
    $('[data-behavior*="sidebarControl"]').removeClass(activeClass);

    $(config.historySelector).removeClass('sidebar-open');

    setTimeout(() => {
      $(this.context).find('.sidebar').removeClass(activeClass);
    }, 300);
  }

  init() {
    this.handleClick();
    this.handleTopics();
  }

  cleanup() {
    PubSub.unsubscribe('/sidebar/close');
    PubSub.unsubscribe('/sidebar/open');
  }
}