import $ from 'jquery';

export default class NavContent {
  constructor(context) {
    this.context = context;
  }

  updateState($el) {
    let activeClass = 'active',
        inactiveClass = 'inactive';

    if ($el.hasClass(activeClass)) {
      $el.removeClass(activeClass);
      $el.siblings().removeClass(inactiveClass);
    } else {
      $el.siblings().removeClass(activeClass);
      $el.siblings().addClass(inactiveClass);
      $el.removeClass(inactiveClass);
      $el.addClass(activeClass);
    }
  }

  handleClick() {
    $(this.context).on('click', '.title', (e) => {
      e.preventDefault();
      this.updateState($(e.target).parents('.section'));
    });
  }

  displayArrows() {
    $(this.context).find('.section').each((index, element) => {
      let $element = $(element),
          wrapperHeight = $element.find('.content-wrapper').height(),
          contentHeight = $element.find('.links').outerHeight() + parseInt($element.find('.content').css('paddingTop'));

      if (contentHeight > wrapperHeight) {
        $element.addClass('display-arrow');
      }
    });
  }

  handleArrows() {
    $(this.context).on('click', '.arrow', (e) => {
      e.preventDefault();

      let $content = $(e.target).parents('.section').find('.content'),
          wrapperHeight = $content.parents('.content-wrapper').height(),
          scrollTop = $content.scrollTop();

      $content.animate({
        scrollTop: scrollTop + wrapperHeight
      });
    });
  }

  init() {
    this.handleClick();
    this.displayArrows();
    this.handleArrows();
  }
}