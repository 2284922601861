import $ from 'jquery';
import PubSub from 'pubsub-js';

export default class NavAboutControl {
  constructor(context) {
    this.context = context;
  }

  handleClick() {
    $(this.context).on('click', (e) => {
      e.preventDefault();
      this.updateContent();
    });
  }

  updateContent() {
    let activeClass = 'active',
        $context = $(this.context);

    if ($context.hasClass(activeClass)) {
      $context.removeClass(activeClass);
      $('#about .section-body .' + activeClass).removeClass(activeClass);
      $('#about .section-body .content').first().addClass(activeClass);
    } else {
      $context.siblings().removeClass(activeClass);
      $context.addClass(activeClass);
      $('#about .section-body .' + activeClass).removeClass(activeClass);
      $('#about .section-body [data-post-name="' + $context.attr('data-post-name') + '"]').addClass(activeClass);
    }

    PubSub.publish('/navAbout/updated');
  }

  init() {
    this.handleClick();
  }
}