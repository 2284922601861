import $ from 'jquery';
import config from 'config';
import Cookies from 'cookies-js';
import initBehaviors from 'modules/behaviors';
import PubSub from 'pubsub-js';

export default class Modal {
  constructor(context) {
    this.context = context;
  }

  initMarkup() {
    if ($('.modal-wrapper').length == 0) {
      $(config.historySelector).append('<div class="modal-wrapper"><div class="modal-content"></div><a href="" class="modal-close"><span class="text-accessibility">Close</span></a><a href="" class="modal-arrow modal-previous"><span class="text-accessibility">Previous</span></a><a href="" class="modal-arrow modal-next"><span class="text-accessibility">Next</span></a></div>');
      this.$modal = $(config.historySelector).find('.modal-wrapper');
    }

    // Add modal class to article images (slightly hacky)
    $('.single-article img').each((index, element) => {
      let $parentLink = $(element).parent('a');

      if ($parentLink) {
        if ($parentLink.hasClass('no-modal') || $parentLink.attr('target') == '_blank') {
          $parentLink.addClass('image-link');
        } else {
          $parentLink.addClass('modal image-link');
          $parentLink.attr('data-modal-gallery', 'true');
        }
      }
    });
  }

  handleOpenClick() {
    $(config.historySelector).on('click', '.modal', (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.$target = $(e.currentTarget);

      if (this.$target.hasClass('active')) {
        this.closeModal();
      } else {
        this.getContent();
      }
    });
  }

  handleCloseClick() {
    this.$modal.on('click', (e) => {
      if ($(e.target).hasClass('modal') || $(e.target).hasClass('modal-content') || $(e.target).hasClass('modal-wrapper') || $(e.target).hasClass('modal-close')) {
        e.preventDefault();
        this.closeModal();
      }
    });
  }

  handleArrowClick() {
    this.$modal.find('.modal-arrow').on('click', (e) => {
      e.preventDefault();

      let direction = 'previous';

      if ($(e.currentTarget).hasClass('modal-next')) {
        direction = 'next';
      }

      this.scrollGallery(direction);
    });
  }

  handleTopics() {
    PubSub.subscribe('/modal/autoOpen', (topic, object) => {
      this.$target = object.$target;
      this.getContent();
    });

    PubSub.subscribe('/history/updated', () => {
      this.initFunctions();
    });

    PubSub.subscribe('/teaser/updated', () => {
      this.initFunctions();
    });

    PubSub.subscribe('/form/submitted', () => {
      this.closeModal();
    });
  }

  handleKeypress() {
    $(document).on('keyup.modal', (e) => {
      if (e.keyCode === 27) {
        this.closeModal();
      }

      if ($(config.historySelector).hasClass('modal-open-gallery')) {
        if (e.keyCode === 37) {
          this.$modal.find('.modal-previous').click();
        }

        if (e.keyCode === 39) {
          this.$modal.find('.modal-next').click();
        }
      }

    });
  }

  unhandleKeypress() {
    $(document).off('keyup.modal');
  }

  getContent() {
    if (this.$target.attr('href') || this.$target.attr('data-modal-target')) {
      let href;

      if (this.$target.attr('data-modal-target')) {
        href = this.$target.attr('data-modal-target');
      } else {
        href = this.$target.attr('href');
      }

      if (href.indexOf('#') === 0) {
        this.openModal(href);
      } else {
        let $image = this.createImage(href);
        this.openModal($image);
      }
    }
  }

  createImage(href) {
    let image = new Image(),
        $image = $('<div class="image-wrapper"></div>'),
        description = this.$target.attr('data-modal-image-description');

    if (description === undefined) {
      if (this.$target.children('img').attr('title') !== undefined && this.$target.children('img').attr('title') !== '') {
        description = this.$target.children('img').attr('title');
      }
    }

    image.src = href;
    $image.append(image);

    if (description !== undefined) {
      $image.append('<div class="modal-description"> ' + description +  ' </div>');
    }

    return $image;
  }

  openModal(element) {
    let $modalContent = this.$modal.find('.modal-content'),
        $clone = $(element).clone();

    $modalContent.html($clone);

    let delay = 0;

    if (this.$target.attr('data-modal-delay')) {
      delay = this.$target.attr('data-modal-delay');
    }

    setTimeout(() => {
      $('html').addClass('modal-open');
      if (this.$target.attr('data-modal-gallery') == 'true') {
        $('html').addClass('modal-open-gallery');
      }

      this.$target.addClass('active');
      this.handleKeypress();
      initBehaviors($modalContent);
    }, delay);
  }

  closeModal() {
    if ($('html').hasClass('modal-open')) {
      let $modalContent = this.$modal.find('.modal-content');

      $('html').addClass('modal-closing');
      this.$target.removeClass('active');
      this.setCookie();

      setTimeout(() => {
        $('html').removeClass('modal-open modal-open-gallery modal-closing');
        $modalContent.html('');
        this.unhandleKeypress();
      }, 300);
    }
  }

  scrollGallery(direction) {
    if (this.$target.parents('.slick-slide').length > 0) {
      let $slide;

      if (direction == 'previous') {
        $slide = this.$target.parents('.slick-slide:not(.slick-cloned)').prev();

        if ($slide.length == 0) {
          $slide = this.$target.parents('.slick-track').find('.slick-slide:not(.slick-cloned):last');
        }
      } else {
        $slide = this.$target.parents('.slick-slide:not(.slick-cloned)').next();

        if ($slide.length == 0) {
          $slide = this.$target.parents('.slick-track').find('.slick-slide:not(.slick-cloned):first');
        }
      }

      this.$target.removeClass('active');
      this.$target = $slide.find('.modal');
      this.$target.addClass('active');
    } else {
      let $elements = this.$target.parents('.scroll').find('.content .modal, .inline-images .modal'),
          currentIndex = $elements.index(this.$target),
          nextIndex;

      if (direction == 'previous') {
        if (currentIndex == 0) {
          nextIndex = $elements.length - 1;
        } else {
          nextIndex = currentIndex - 1;
        }
      } else {
        if (currentIndex == $elements.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = currentIndex + 1;
        }
      }

      this.$target.removeClass('active');
      this.$target = $elements.eq(nextIndex);
      this.$target.addClass('active');
    }

    this.galleryUpdate(this.$target.attr('href'));
  }

  galleryUpdate(href) {
    let $image = this.createImage(href),
        $modalContent = this.$modal.find('.modal-content');

    $modalContent.html($image);
  }

  setCookie() {
    let key = this.$target.attr('data-cookie-key'),
        value = this.$target.attr('data-cookie-value');

    if (key && value) {
      Cookies.set(key, value);
    }
  }

  initFunctions() {
    this.initMarkup();
    this.handleOpenClick();
    this.handleCloseClick();
    this.handleArrowClick();
  }

  init() {
    this.initFunctions();
    this.handleTopics();
  }
}