/* --------------------------------------------------------------------------
    The FRONT
----------------------------------------------------------------------------- */

// Init Behaviors
import initBehaviors from 'modules/behaviors';
initBehaviors();

// Attach FastClick
let attachFastClick = require('fastclick');
attachFastClick(document.body);