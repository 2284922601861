import $ from 'jquery';

export default class selectText {
  constructor(context) {
    this.context = context;
  }

  initMarkup() {
    $(this.context).after('<div class="text-sharer"></div>');
    this.$sharer = $(this.context).siblings('.text-sharer');
    this.$sharer.html($('.nav-controls .share-controls').children().clone());
  }

  handleSelect() {
    let pageX,
        pageY;

    $(document).on('mouseup.selectText', (e) => {
      pageX = e.pageX;
      pageY = e.pageY + $(this.context).parents('.scroll').scrollTop();

      let selectedText = this.getSelected();

      if (selectedText != '') {
        this.updateSharerText(selectedText);

        this.$sharer.css({
          'left': pageX + 5,
          'top' : pageY - 55
        });

        this.$sharer.addClass('active');
      } else {
        this.$sharer.removeClass('active');
      }
    });
  }

  getSelected() {
    let t = '';

    if (window.getSelection) {
        t = window.getSelection().toString();
    } else if (document.getSelection) {
        t = document.getSelection().toString();
    } else if (document.selection) {
        t = document.selection.createRange().text;
    }

    return t;
  }

  updateSharerText(selectedText) {
    let twitterLink = this.$sharer.find('.twitter').attr('href');
    this.$sharer.find('.twitter').attr('href', twitterLink.replace(/(text=)[^\&]+/, 'text=\"' + selectedText + '\"'));

    let emailLink = this.$sharer.find('.email').attr('href');
    this.$sharer.find('.email').attr('href', emailLink.replace(/(body=)[^\(%)]+/, 'body=\"' + selectedText + '\"'));
  }

  init() {
    this.initMarkup();
    this.handleSelect();
  }

  cleanup() {
    $(document).off('mouseup.selectText');
  }
}