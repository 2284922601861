import $ from 'jquery';
import config from 'config';

import article from '../behaviors/article';
import blockGallery from '../behaviors/blockGallery';
import episode from '../behaviors/episode';
import form from '../behaviors/form';
import gallery from '../behaviors/gallery';
import history from '../behaviors/history';
import modal from '../behaviors/modal';
import modalAutoOpen from '../behaviors/modalAutoOpen';
import navAbout from '../behaviors/navAbout';
import navAboutControl from '../behaviors/navAboutControl';
import navContent from '../behaviors/navContent';
import navMainControls from '../behaviors/navMainControls';
import parallax from '../behaviors/parallax';
import pervasive from '../behaviors/pervasive';
import progressIndicator from '../behaviors/progressIndicator';
import publishTopic from '../behaviors/publishTopic';
import selectText from '../behaviors/selectText';
import sidebar from '../behaviors/sidebar';
import sidebarControl from '../behaviors/sidebarControl';
import slider from '../behaviors/slider';
import sliderSwipe from '../behaviors/sliderSwipe';
import teaserControl from '../behaviors/teaserControl';
import toggleClass from '../behaviors/toggleClass';
import videoPlayer from '../behaviors/videoPlayer';

const behaviors = {
  'article': article,
  'blockGallery': blockGallery,
  'episode': episode,
  'form': form,
  'gallery': gallery,
  'history': history,
  'modal': modal,
  'modalAutoOpen': modalAutoOpen,
  'navAbout': navAbout,
  'navAboutControl': navAboutControl,
  'navContent': navContent,
  'navMainControls': navMainControls,
  'parallax': parallax,
  'pervasive': pervasive,
  'progressIndicator': progressIndicator,
  'publishTopic': publishTopic,
  'selectText': selectText,
  'sidebar': sidebar,
  'sidebarControl': sidebarControl,
  'slider': slider,
  'sliderSwipe': sliderSwipe,
  'teaserControl': teaserControl,
  'toggleClass': toggleClass,
  'videoPlayer': videoPlayer
};

let activeBehaviors = [];

function cleanBehaviors() {
  $.each(activeBehaviors, (index, Behavior) => {
    if (Behavior) {
      if ($(Behavior.context).parents(config.historySelector).length > 0 || $(Behavior.context).hasClass(config.historySelector)) {
        if (Behavior.cleanup) {
          Behavior.cleanup();
        }

        activeBehaviors[index] = null;
      }
    }
  });

  for (var i = 0; i < activeBehaviors.length; i++) {
    if (activeBehaviors[i] === null) {
      activeBehaviors.splice(i, 1);
      i--;
    }
  }
}

export default function initBehaviors($context) {
  if ($context === undefined) {
    $context = $(document);
    cleanBehaviors();
  }

  if ($context.hasClass(config.historyClass)) {
    cleanBehaviors();
  }

  $context.find('[data-behavior]').addBack('[data-behavior]').each((index, element) => {
    let behaviorList = element.getAttribute('data-behavior');

    $.each(behaviorList.split(' '), (index, behaviorName) => {
      if (behaviors[behaviorName]) {
        let Behavior = new behaviors[behaviorName](element);

        if (Behavior) {
          Behavior.init();
          activeBehaviors.push(Behavior);
        } else {
          console.error('The FRONT: Unable to initialize behavior "' + behaviorName + '"');
        }
      } else {
        console.error('The FRONT: No such behavior "' + behaviorName + '"');
      }
    });
  });
}