import $ from 'jquery';
import PubSub from 'pubsub-js';

export default class PublishTopic {
  constructor(context) {
    this.context = context;
  }

  handleClick() {
    $(this.context).on('click', (e) => {
      e.preventDefault();
      PubSub.publish(this.context.getAttribute('data-topic'));
    });
  }

  init() {
    this.handleClick();
  }
}