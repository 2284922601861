import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
require('libraries/parallax.js');

export default class Parallax {
  constructor(context) {
    this.context = context;
  }

  init() {
    $('.parallax').each((index, element) => {
      imagesLoaded($(element), () => {
        $(element).parallax({
          bleed: 40
        });
      });
    });
  }
}