import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
require('slick-carousel');

export default class BlockGallery {
  constructor(context) {
    this.context = context;
  }

  initGallery() {
    this.slick = $(this.context).slick({
      accessibility: false,
      arrows: false,
      centerMode: true,
      ease: 'cubic-bezier(.62, .28, .23, .99)',
      variableWidth: true
    });

    // Hack to prevent left side bug
    $(this.context)[0].slick.refresh();

    // Additional refresh on images loaded
    imagesLoaded($(this.context), () => {
      $(this.context)[0].slick.refresh();
    });
  }

  handleChange() {
    this.slick.on('beforeChange', () => {
      $(this.context).find('.modal').each((index, element) => {
        $(element).removeClass('modal');
      });
    });

    this.slick.on('afterChange', () => {
      $(this.context).find('img').each((index, element) => {
        if ($(element).parent('a')) {
          $(element).parent('a').addClass('modal');
        }
      });
    });
  }

  handleArrowClick() {
    $(this.context).siblings('.block-gallery-arrow').on('click', (e) => {
      e.preventDefault();

      if ($(e.currentTarget).hasClass('previous')) {
        $(this.context).slick('slickPrev');
      } else {
        $(this.context).slick('slickNext');
      }
    });
  }

  init() {
    this.initGallery();
    this.handleChange();
    this.handleArrowClick();
  }
}