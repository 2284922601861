import $ from 'jquery';
import _ from 'underscore';
import config from 'config';
import IScroll from 'iscroll';

export default class Slider {
  constructor(context) {
    this.context = context;
  }

  initIScroll() {
    let sliderElement = this.context.querySelector('.slider');

    this.myScroll = new IScroll(sliderElement, {
      click: true,
      mouseWheel: true,
      scrollX: true,
      snap: '.item'
    });
  }

  handleItemClick() {
    $(this.context).find('.item').on('click', (e) => {
      this.myScroll.scrollToElement(e.currentTarget);
    });
  }

  handleArrowClick() {
    $(this.context).find('.arrow').on('click', (e) => {
      e.preventDefault();

      if (!$(config.historySelector).hasClass('teaser-updating')) {
        let $next;

        if ($(e.currentTarget).hasClass('previous')) {
          $next = $(this.context).find('.item.open').prev();

          if ($next.length == 0) {
            $next = $(this.context).find('.item:last');
          }
        } else {
          $next = $(this.context).find('.item.open').next();

          if ($next.length == 0) {
            $next = $(this.context).find('.item:first');
          }
        }

        $next.click();
      }
    });
  }

  handleResize() {
    let debouncedFunction = _.debounce(() => {
      this.updateWidth();
    }, 300);

    $(window).on('resize.slider', debouncedFunction);
  }

  updateWidth() {
    let itemWidth = 295;

    if ($(window).width() < 768) {
      itemWidth = 240;
    }

    let $context = $(this.context),
        $wrapper = $(this.context).find('.slider-wrapper'),
        fitCount = Math.floor($context.width() / itemWidth),
        newWidth = itemWidth * fitCount,
        maxWidth = $(this.context).find('.item').length * itemWidth;

    if (newWidth > maxWidth) {
      newWidth = maxWidth;
    }

    $wrapper.width(newWidth);

    if (this.myScroll) {
      let displayElement = this.context.querySelector('.item.open');

      this.myScroll.refresh();
      this.myScroll.scrollToElement(displayElement);
    }
  }

  init() {
    this.updateWidth();
    this.initIScroll();
    this.handleItemClick();
    this.handleArrowClick();
    this.handleResize();
  }

  cleanup() {
    $(window).off('resize.slider');
  }
}