import $ from 'jquery';
import PubSub from 'pubsub-js';

export default class Pervasive {
  constructor(context) {
    this.context = context;
  }

  saveRules() {
    this.$pervasiveRules = $('#pervasive-rules').clone();
  }

  handleTopics() {
    PubSub.subscribe('/pervasive/colorReplace', (topic, object) => {
      this.replaceColor(object.color);
    });

    PubSub.subscribe('/pervasive/colorReset', () => {
      this.resetColor();
    });

    PubSub.subscribe('/pervasive/colorOverride', (topic, object) => {
      this.overrideColor(object.color);
    });

    PubSub.subscribe('/pervasive/removeOverride', () => {
      this.removeOverride();
    });
  }

  replaceColor(color) {
    let $replace = this.$pervasiveRules.clone(),
        html;

    html = $replace.html().replace(/:.+?;/g, ': ' + color + ';');

    $replace.html(html);

    $('#pervasive-rules').replaceWith($replace);
  }

  resetColor() {
    $('#pervasive-rules').replaceWith(this.$pervasiveRules);
  }

  overrideColor(color) {
    let $override = this.$pervasiveRules.clone(),
        html = $override.html().replace(/:.+?;/g, ': ' + color + ';');

    $override.html(html);
    $override.attr('id', 'pervasive-rules-override');

    if ($('#pervasive-rules-override').length > 0) {
      $('#pervasive-rules-override').replaceWith($override);
    } else {
      $('#pervasive-rules').after($override);
    }
  }

  removeOverride() {
    $('#pervasive-rules-override').remove();
  }

  init() {
    this.saveRules();
    this.handleTopics();
  }

  cleanup() {
    PubSub.unsubscribe('/pervasive/colorReplace');
    PubSub.unsubscribe('/pervasive/colorReset');
    PubSub.unsubscribe('/pervasive/colorOverride');
    PubSub.unsubscribe('/pervasive/removeOverride');
  }
}