import $ from 'jquery';
import config from 'config';
import initBehaviors from 'modules/behaviors';
import Hammer from 'hammerjs';
import PubSub from 'pubsub-js';

export default class TeaserControl {
  constructor(context) {
    this.context = context;
    this.openClass = 'open';
    this.updatingClass = 'teaser-updating';
    this.delayTime = 300;
  }

  handleClick() {
    $(this.context).on('click', (e) => {
      e.preventDefault();

      if (!$(config.historySelector).hasClass(this.updatingClass)) {
        if (!$(this.context).hasClass(this.openClass)) {
          this.disableLink();
          this.getContent($(this.context).attr('href'));
          this.updateOpen();
        }
      }
    });
  }

  handleHammer() {
    var hammertime = new Hammer(this.context);

    hammertime.on('tap', () => {
      $(this.context).click();
    });
  }

  disableLink() {
    $(config.historySelector).addClass(this.updatingClass);
    $('#content .header a').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }

  getContent(url) {
    $(config.historySelector).addClass(this.updatingClass);

    this.setStartTime();

    $.ajax({url: url})
      .done((data) => {
        let readyTime = new Date,
            timeDifference = readyTime - this.startTime;

        if (timeDifference > this.delayTime) {
          this.updateContent(data);
        } else {
          setTimeout(() => {
            this.updateContent(data);
          }, this.delayTime - timeDifference);
        }
      })
      .error(() => {
        $(config.historySelector).removeClass(this.updatingClass);
      });
  }

  setStartTime() {
    this.startTime = new Date;
  }

  updateContent(data) {
    let $data = $(data),
        $content = $data.find('#content'),
        $container = $content.parents(config.historySelector);

    if ($('#about-item').length) {
      $('#about-item').replaceWith($content.children('#about-item'));
    } else {
      $('#content').prepend($content.children('#about-item'));
    }

    if ($('#content').children('.header').length) {
      $('#content').children('.header').replaceWith($content.children('.header'));
    } else {
      $('#content').prepend($content.children('.header'));
    }

    $('#pervasive-rules').replaceWith($data.find('#pervasive-rules'));
    $('#header-text-rules').replaceWith($data.find('#header-text-rules'));

    if ($container.hasClass(config.noShadowClass)) {
      $(config.historySelector).addClass(config.noShadowClass);
    } else {
      $(config.historySelector).removeClass(config.noShadowClass);
    }

    initBehaviors($('#content').children('.header'));

    PubSub.publish('/teaser/updated');

    setTimeout(() => {
      $(config.historySelector).removeClass(this.updatingClass);
    }, 100);
  }

  updateOpen() {
    $(this.context).siblings('.' + this.openClass ).removeClass(this.openClass );
    $(this.context).addClass(this.openClass );
  }

  init() {
    this.handleClick();
    this.handleHammer();
  }
}