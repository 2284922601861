import $ from 'jquery';
import config from 'config';
import Cookies from 'cookies-js';
import PubSub from 'pubsub-js';

export default class VideoPlayer {
  constructor(context) {
    this.context = context;
  }

  injectYouTubeAPIScript() {
    let tag = document.createElement('script');
    tag.src = '//www.youtube.com/player_api';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  handleYouTubePlayerAPIReady() {
    window.onYouTubePlayerAPIReady = () => {
      this.initPlayer();
    };
  }

  initPlayer() {
    this.player = new window.YT.Player(this.context);
    this.bindListeners();
  }

  handleTopics() {
    PubSub.subscribe('/video/pause', () => {
      this.pauseVideo();
    });

    PubSub.subscribe('/video/replay', () => {
      this.rewindVideo();
      $(config.historySelector).removeClass('video-did-stop');
      this.playVideo();
    });

    PubSub.subscribe('/video/start', () => {
      this.playVideo();
    });

    PubSub.subscribe('/video/stop', () => {
      this.stopVideo();
    });
  }

  handleMouse() {
    $(this.context).on('mouseout.videoPlayer', () => {
      this.removeHideElementsClass();
    });

    $(this.context).on('mouseover.videoPlayer', () => {
      this.addHideElementsClass();
    });
  }

  destroyMouseHandler() {
    $(this.context).off('mouseout.videoPlayer');
    $(this.context).off('mouseover.videoPlayer');
  }

  setCookie() {
    if ($(this.context).attr('data-cookie-key') && $(this.context).attr('data-cookie-value')) {
      Cookies.set($(this.context).attr('data-cookie-key'), $(this.context).attr('data-cookie-value'));
    }
  }

  bindListeners() {
    this.player.addEventListener('onStateChange', (e) => {
      // Ended
      if (e.data === 0) {
        this.stopVideo();
      }

      // Playing
      if (e.data === 1) {
        this.addVideoDidStartClass();
        this.addHideElementsClass();
        this.handleMouse();
      }

      // Paused
      if (e.data === 2) {
        this.pauseVideo();
      }
    });
  }

  pauseVideo() {
    this.removeHideElementsClass();
    this.destroyMouseHandler();
  }

  playVideo() {
    this.player.playVideo();
    this.addPlayingClass();
  }

  stopVideo() {
    this.player.stopVideo();
    this.removePlayingClass();
    this.removeHideElementsClass();
    this.destroyMouseHandler();
    this.setCookie();
    $(config.historySelector).addClass('video-did-stop');
  }

  rewindVideo() {
    this.player.seekTo(0);
  }

  addVideoDidStartClass() {
    $(config.historySelector).addClass('video-did-start');
  }

  addPlayingClass() {
    $(this.context).addClass('playing');
  }

  removePlayingClass() {
    $(this.context).removeClass('playing');
  }

  addHideElementsClass() {
    $(config.historySelector).addClass('hide-elements');
  }

  removeHideElementsClass() {
    $(config.historySelector).removeClass('hide-elements');
  }

  init() {
    if (!window.YT) {
      this.injectYouTubeAPIScript();
      this.handleYouTubePlayerAPIReady();
    } else {
      this.initPlayer();
    }

    this.handleTopics();
  }

  cleanup() {
    PubSub.unsubscribe('/video/pause');
    PubSub.unsubscribe('/video/replay');
    PubSub.unsubscribe('/video/start');
    PubSub.unsubscribe('/video/stop');
  }
}