import $ from 'jquery';

export default class ProgressIndicator {
  constructor(context) {
    this.context = context;
  }

  initMarkup() {
    let colorStyle = '',
        $context = $(this.context);

    if ($context.attr('data-progress-color')) {
      colorStyle = ' style="background: ' + $context.attr('data-progress-color') + ';"';
    }

    $context.before('<div class="progress"><div class="indicator"' + colorStyle +'></div></div>');
    this.$indicator = $(this.context).prev();
  }

  handleScroll() {
    $(document).on('scroll.progressIndicator', () => {
      this.updateIndicator();
      this.updateClasses();
    });
  }

  updateIndicator() {
    let percent = $(document).scrollTop() / ($(document).height() - $(window).height()) * 100;

    this.$indicator.find('.indicator').css({
      'width': percent + '%'
    });
  }

  updateClasses() {
    if ($(document).scrollTop() + $(window).height() > $(document).height() - $(this.context).find('.related-content').height()) {
      $(this.context).addClass('bottom');
    } else {
      $(this.context).removeClass('bottom');
    }
  }

  init() {
    this.initMarkup();
    this.handleScroll();
  }

  cleanup() {
    $(document).off('scroll.progressIndicator');
  }
}