import $ from 'jquery';
import _ from 'underscore';
import config from 'config';
import PubSub from 'pubsub-js';

export default class NavAbout {
  constructor(context) {
    this.context = context;
  }

  handleResize() {
    let debouncedFunction = _.debounce(() => {
      this.updateNavigation();
    }, 300);

    $(window).on('resize.navAbout', debouncedFunction);
  }

  handleTopics() {
    PubSub.subscribe('/navAbout/updated', () => {
      this.updateNavigation();
    });
  }

  updateNavigation() {
    let $sectionBody = $(this.context).find('.section-body'),
        dataPostName = $sectionBody.find('.active').attr('data-post-name');

    if ($(window).width() > config.mobileBreakpoint || (!dataPostName)) {
      $(this.context).find('.section-links').after($sectionBody);
    } else {
      $(this.context).find('.section-links a[data-post-name="' + dataPostName +  '"]').after($sectionBody);
    }
  }

  init() {
    this.handleResize();
    this.handleTopics();
  }

  cleanup() {
    $(window).off('resize.navAbout');
    PubSub.unsubscribe('/navAbout/updated');
  }
}