import $ from 'jquery';

export default class ToggleClass {
  constructor(context) {
    this.context = context;
  }

  handleClick() {
    $(this.context).on('click', (e) => {
      e.preventDefault();

      let $context = $(this.context),
          target = $context.attr('data-toggle-target'),
          htmlClass = $context.attr('data-toggle-class');

      if (target) {
        this.toggleClass(target, htmlClass);
      } else {
        this.toggleClass(this.context, htmlClass);
      }
    });
  }

  toggleClass(target, htmlClass) {
    $(target).toggleClass(htmlClass);
  }

  init() {
    this.handleClick();
  }
}